<template>
  <b-modal
    id="modal-reservation-cancel"
    ref="refModalCancel"
    :title="t('Hủy đặt cọc')"
    size="lg"
    :ok-title="isSubmitting ? t('Đang lưu') : t('Lưu')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >

    <b-alert
      v-if="item"
      show
      variant="danger"
    >
      <div class="alert-body">
        <feather-icon
          class="mr-25"
          icon="StarIcon"
        />
        <span class="ml-25">{{ t('Bạn đang hủy cọc') }}  {{ item.apartment ? `${t('tòa nhà')} ${item.apartment.name}` : '' }} {{ item.room ? `${t('phòng')} ${item.room.name}` : '' }} {{ item.bed ? `${t('giường')} ${item.bed.name}` : '' }} {{ item.tenant ? `${t('của khách hàng')} ${item.tenant.name}` : '' }}. {{ t('Số tiền đặt cọc đã nhận là') }} {{ item.invoice ? Number(item.invoice.paid).toLocaleString() : Number(item.deposit).toLocaleString() }}.</span>
      </div>
    </b-alert>
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="Số tiền hoàn cọc"
              :rules="`required|maxValue:${item.invoice ? item.invoice.paid : item.deposit}`"
            >
              <number-input
                v-model="itemLocal.refund"
                required
                :default-value="itemLocal.refund"
                :label="t('Số tiền hoàn cọc')"
                placeholder="1,000,000"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <!-- Description -->
            <validation-provider
              #default="validationContext"
              name="Lý do hủy cọc"
              rules="required"
            >
              <b-form-group
                :label="t('Lý do hủy cọc')"
              >
                <template
                  v-slot:label
                >
                  {{ t('Lý do hủy cọc') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-textarea
                  v-model="itemLocal.refundReason"
                  :state="getValidationState(validationContext)"
                  rows="3"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BAlert,
} from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, maxValue } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import NumberInput from '@/views/components/NumberInput.vue';
import useReservationCancelModal from "./useReservationCancelModal";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BAlert,
    NumberInput,
    BFormGroup,
    BFormTextarea,
    BFormInvalidFeedback,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      maxValue,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalCancel,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      t,
      onSubmit,
    } = useReservationCancelModal(props, emit, refFormObserver);

    return {
      refModalCancel,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      t,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
