import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import reservationStoreModule from '../reservationStoreModule';

export default function useReservationPreviewModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'reservation';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, reservationStoreModule);
  }
  const { t } = useI18nUtils();
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const pdfUrl = ref(null);
  const isLoading = ref(false);
  const toastification = toast();
  const resetModal = () => {
    resetItemLocal();
  };

  const fetchPdfPreviewUrl = () => {
    isLoading.value = true;
    resetModal();
    if (!itemLocal || !itemLocal.value || !itemLocal.value.id) {
      pdfUrl.value = null;
      isLoading.value = false;
      return;
    }
    // if (itemLocal.value.previewUrl) {
    //   pdfUrl.value = itemLocal.value.previewUrl;
    //   isLoading.value = false;
    //   return;
    // }
    store.dispatch('reservation/previewReservation', itemLocal.value.id).then(response => {
      pdfUrl.value = response.data;
      isLoading.value = false;
    }).catch(error => {
      toastification.showToastError(error, refForm.value);
      isLoading.value = false;
    });
  };

  return {
    refModal,
    refForm,
    itemLocal,
    pdfUrl,
    isLoading,
    fetchPdfPreviewUrl,
    resetItemLocal,
    resetModal,
    t,
  };
}
